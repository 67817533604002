<template>
  <div>
    <v-container fluid>
      <v-row v-if="isResponsive">
        <v-col cols="12">
          <div>
            <p class="text-title mon-bold">{{ texts.orders.textTitle }}</p>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="display-flex align-items-center mb-4">
            <v-text-field
              v-model="sSearch"
              type="text"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular mr-4"
              :placeholder="texts.orders.filters.textSearch"
              persistent-placeholder
              clearable
              append-icon="mdi-magnify"
              maxLength="100"
            ></v-text-field>
            <orders-filter-layout
              v-if="showFilter"
              :tab="tab"
              :texts="texts"
            />
          </div>
          <div>
            <v-menu
              ref="bMenuFinal"
              v-model="bMenuFinal"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  color="#000000"
                  dense
                  class="global-inputs mon-regular mb-4"
                  readonly
                  v-model="startDateTextField"
                  clearable
                  hide-details
                  append-icon="mdi-calendar"
                  @click:clear="
                    startDate = null;
                    endDate = null;
                    endDateTextField= null;
                  "
                  :placeholder="texts.orders.filters.startDate"
                  :label="texts.orders.filters.startDate"
                  persistent-hint
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="#C41F79"
                v-model="startDate"
                @change="ChangeFormatStartDateTextField()"
                no-title
                @input="bMenuFinal = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div>
            <v-menu
              ref="bMenuInitial"
              v-model="bMenuInitial"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  color="#000000"
                  dense
                  class="global-inputs mon-regular mb-4"
                  readonly
                  v-model="endDateTextField"
                  clearable
                  hide-details
                  append-icon="mdi-calendar"
                  @click:clear="endDate = null; endDateTextField = null;"
                  :placeholder="texts.orders.filters.endDate"
                  :label="texts.orders.filters.endDate"
                  persistent-hint
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="startDate"
                color="#C41F79"
                v-model="endDate"
                @change="ChangeFormatEndDateTextField()"
                no-title
                @input="bMenuInitial = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          cols="12"
          md="4"
        >
          <div>
            <p class="text-title mon-bold">{{ texts.orders.textTitle }}</p>
          </div>
        </v-col>
        <!-- filters -->
        <v-col
          cols="12"
          md="8"
        >
          <div class="display-flex align-items-center height-100">
            <v-text-field
              v-model="sSearch"
              type="text"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular mr-2"
              :placeholder="texts.orders.filters.textSearch"
              persistent-placeholder
              clearable
              append-icon="mdi-magnify"
              maxLength="100"
            ></v-text-field>
            <v-menu
              ref="bMenuFinal"
              v-model="bMenuFinal"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  color="#000000"
                  dense
                  class="global-inputs inputs-date mon-regular mr-2"
                  readonly
                  v-model="startDateTextField"
                  clearable
                  hide-details
                  append-icon="mdi-calendar"
                  @click:clear="
                    startDate = null;
                    endDate = null;
                    endDateTextField= null;
                  "
                  :placeholder="texts.orders.filters.startDate"
                  :label="texts.orders.filters.startDate"
                  persistent-hint
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="#C41F79"
                v-model="startDate"
                @change="ChangeFormatStartDateTextField()"
                no-title
                @input="bMenuFinal = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              ref="bMenuInitial"
              v-model="bMenuInitial"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  color="#000000"
                  dense
                  class="global-inputs inputs-date mon-regular mr-2"
                  readonly
                  v-model="endDateTextField"
                  clearable
                  hide-details
                  append-icon="mdi-calendar"
                  @click:clear="endDate = null; endDateTextField = null;"
                  :placeholder="texts.orders.filters.endDate"
                  :label="texts.orders.filters.endDate"
                  persistent-hint
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="startDate"
                color="#C41F79"
                v-model="endDate"
                @change="ChangeFormatEndDateTextField()"
                no-title
                @input="bMenuInitial = false"
              ></v-date-picker>
            </v-menu>
            <orders-filter-layout
              v-if="showFilter"
              :tab="tab"
              :texts="texts"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="container-profile">
            <template>
              <v-tabs
                v-model="tab"
                show-arrows
                background-color="transparent"
              >
                <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
                <v-tab
                  class="tabs-profile mon-regular"
                  @click="tab1"
                >{{
                  texts.orders.textTab1
                }}</v-tab>
                <v-tab
                  class="tabs-profile mon-regular"
                  @click="tab2"
                >{{
                  texts.orders.textTab2
                }}</v-tab>
                <v-tab
                  class="tabs-profile mon-regular"
                  @click="tab3"
                >{{
                  texts.orders.textTab3
                }}</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <orders-tab-content-one-layout
                    :texts="texts"
                    :tab="tab"
                  />
                </v-tab-item>
                <v-tab-item>
                  <orders-tab-content-two-layout
                    :texts="texts"
                    :tab="tab"
                  />
                </v-tab-item>
                <v-tab-item>
                  <orders-tab-content-three-layout
                    :texts="texts"
                    :tab="tab"
                  />
                </v-tab-item>
              </v-tabs-items>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "OrdersContentLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      tab: 0,
      sSearch: "",
      bMenuFinal: false,
      bMenuInitial: false,
      startDate: null,
      startDateTextField: null,
      endDate: null,
      endDateTextField: null,
      status: "",
      paymentStatus: "",
      showFilter: true,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.tab = this.sTabPosition;
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    clearFilters: function () {
      this.sSearch = "";
      this.$store.commit("setSearch", "");
      this.startDate = null;
      this.$store.commit("setStartDate", null);
      this.endDate = null;
      this.endDateTextField = null;
      this.$store.commit("setEndDate", null);
      this.$store.commit("setOrderStatus", []);
      this.$store.commit("setPaymentStatus", null);
    },
    tab1: function () {
      this.clearFilters();
    },
    tab2: function () {
      this.clearFilters();
    },
    tab3: function () {
      this.clearFilters();
    },
    ChangeFormatStartDateTextField() {
      let aDate = this.startDate.split("-");

      let day = aDate[2];
      let month = aDate[1];
      let year = aDate[0];
      let date = month + "/" + day + "/" + year;
      this.startDateTextField = date;
    },
    ChangeFormatEndDateTextField() {
      let aDate = this.endDate.split("-");

      let day = aDate[2];
      let month = aDate[1];
      let year = aDate[0];
      let date = month + "/" + day + "/" + year;
      this.endDateTextField = date;
    },
  },
  computed: {
    sTabPosition() {
      return this.$store.state.sTabPosition;
    },
  },
  watch: {
    tab: function () {
      // this.tab == 1 ? (this.showFilter = false) : (this.showFilter = true);
      this.$store.commit("setTabPosition", this.tab);

    },
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 300),
    startDate: function () {
      this.$store.commit("setStartDate", this.startDate);
    },
    endDate: function () {
      this.$store.commit("setEndDate", this.endDate);
    },
  },
};
</script>

<style>
.container-profile .v-slide-group__content {
  margin-bottom: 4px !important;
}
</style>

<style scoped>
.inputs-date {
  width: 145px !important;
  flex: none !important;
}

.button-filter {
  width: 40px !important;
  height: 40px !important;
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  opacity: 1;
}

.separator-line-tab {
  background: transparent linear-gradient(268deg, #fffc81 0%, #7eff86 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  margin-left: 2px;
}

.tabs-profile {
  font-size: 14px;
  text-transform: initial;
  letter-spacing: 0px;
  text-align: center;
  /* background: red; */
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.v-tab--active {
  font-family: "montserrat-bold", sans-serif;
  color: #3d414a !important;
}

.v-tabs-items {
  background-color: transparent !important;
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: 0;
}

.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0;
}

.text-title {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.v-container-fluid {
  max-width: 90%;
}

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.height-100 {
  height: 100%;
}

@media (max-width: 960px) {
  .v-container-fluid {
    max-width: 100%;
  }
}
</style>